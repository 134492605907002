<template>
  <div>
    <ol
      v-if="!showUserSettings"
      class="breadcrumb mb-2"
    >
      <li class="breadcrumb-item">
        <a
          href="/"
          class="router-link-active"
          target="_self"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="16px"
          height="16px"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="align-text-top feather feather-home"
        ><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></a>
      </li><li class="breadcrumb-item active">
        <span aria-current="location"> {{ $t('Settings') }} </span>
      </li><li class="breadcrumb-item active">
        <span aria-current="location"> {{ $t('Users') }} </span>
      </li>

    </ol>
    <div
      v-if="!showUserSettings"
      class="pb-2"
    >
      <div class="custom-search d-flex justify-content-end align-items-center">
        <div
          v-if="canViewUsers"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            id="inactive-user-switch"
            v-model="includeInactive"
            class="colorful-switch"
            switch
          />
          <label for="inactive-user-switch">
            <h6 class="cursor-pointer mb-0">
              {{ $t('Include Inactive Users') }}
            </h6>
          </label>
        </div>
      </div>

      <b-row class="justify-content-between align-items-center mt-2 mb-1">
        <b-col
          id="tour-invite-users"
          md="6"
          class="d-flex align-items-baseline mb-1"
        >
          <h3>
            {{ $t('Users') }}
          </h3>
          <b-button
            v-if="canInviteUser"
            id="invite-user-btn"
            v-b-modal.invite-user-modal
            variant="outline-secondary"
            class="ml-2"
          >
            <feather-icon icon="MailIcon" />
            {{ $t('Invite User By Email') }}
          </b-button>
        </b-col>

        <b-col
          class="d-flex align-items-baseline justify-content-end"
          md="6"
        >
          <b-form-group :class="{'w-100': IS_MOBILE()}">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search Users')"
              type="text"
              class="d-inline-block"
              @input="getUsers"
            />
          </b-form-group>
          <manage-permissions
            v-if="canManagePermissions"
            :permissions="permissions"
            class="ml-1"
          />
        </b-col>
      </b-row>
      <b-overlay
        :show="isProcessing"
        class="users-list"
      >
        <b-card body-class="p-0">
          <vue-good-table
            id="users-list-table"
            :columns="columns"
            :rows="rows"
            :row-style-class="getRowStyle"
            @on-cell-click="onCellClick"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'id'">
                {{ ((currentPage - 1) * 10) + props.row.originalIndex + 1 }}.
              </span>
              <span v-else-if="props.column.field === 'name'">
                <b-avatar
                  :variant="getRandomVariant()"
                  :src="getAvatar(props.row.avatar)"
                  badge
                  :badge-variant="props.row.isOnline ? 'success' : 'secondary'"
                  :text="getInitials(props.row.name)"
                />
                {{ IS_MOBILE() ? FORMAT_NAME(props.row.name) : props.row.name }}
              </span>
              <span v-else-if="props.column.field === 'role'">
                {{ props.row.role }}
              </span>
              <span v-else-if="props.column.field === 'projectRole'">
                {{ props.row.projectRole }}
              </span>
              <span v-else-if="props.column.field === 'settings'">
                <b-button
                  v-b-tooltip.hover.bottom
                  variant="outline-primary"
                  size="sm"
                  :title="$t('change-settings', { user: props.row.name })"
                  @click.stop="viewUserSettings(props.row)"
                >
                  <feather-icon
                    icon="SettingsIcon"
                    size="20"
                  />
                </b-button>
              </span>
            </template>

            <template slot="emptystate">
              <p class="text-center">
                {{ $t('No Users Found') }}
              </p>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>

      <b-pagination
        v-if="totalItems > perPage"
        v-model="currentPage"
        class="float-right pb-2"
        :total-rows="totalItems"
        :per-page="perPage"
        aria-controls="users-list-table"
        @input="() => { isProcessing = true; getUsers() }"
      />
    </div>

    <invite-user v-if="canInviteUser" />
    <profile-modal
      :user-info="selectedUser"
      @close="selectedUser = null"
    />

    <!-- User Settings -->
    <user-settings
      v-if="showUserSettings"
      :user="userForUserSettings"
      :can-view-users="canViewUsers"
      :permissions="userSettingsPermissions"
      @hide="() => showUserSettings = false"
      @refetch-users="getUsers"
    />
  </div>
</template>
<script>
import {
  BAvatar, BFormGroup, BFormInput, BButton, BCard, BOverlay, BPagination, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import EventBus from '@/event-bus'
import useApollo from '@/plugins/graphql/useApollo'
import { checkPermission } from '@/utils/permissions'
import ManagePermissions from '@/views/common/components/ManagePermissions.vue'
import { setComponentReady } from '@/views/tour/tour'
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import InviteUser from './InviteUser.vue'
import ProfileModal from './profile.vue'
import UserSettings from './Settings/Index.vue'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    InviteUser,
    ProfileModal,
    UserSettings,
    ManagePermissions,
  },
  data() {
    return {
      searchTerm: '',
      rows: [],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      selectedUser: null,
      showUserSettings: false,
      userForUserSettings: '',
      includeInactive: false,
      isProcessing: true,
      permissions: ['projects.invite', 'project.viewusers', 'project.manageusers', 'timing.limit', 'project.usersettings'],
      needPermissionCheck: false,
    }
  },
  computed: {
    ...mapGetters('permissions', [
      'canManagePermissions',
      'canInviteUser',
      'canViewUsers',
      'canManageUsers',
      'canEditTimeLimits',
      'canEditUserSettings',
    ]),
    userSettingsPermissions() {
      return {
        canEditTimingLimit: this.canEditTimeLimits,
        canEditUserSettings: this.canEditUserSettings,
        canManageUsers: this.canManageUsers,
      }
    },
    canAccessUserSettings() {
      return Object.values(this.userSettingsPermissions).some(permission => permission === true)
    },
    requireUserPermissionCheck() {
      return (!(this.canInviteUser
        || this.canViewUsers
        || this.canEditTimeLimits
        || this.canEditUserSettings))
    },
    columns() {
      return [
        { label: this.$t('ID'), field: 'id' },
        { label: this.$t('Name'), field: 'name', tdClass: 'cursor-pointer name-col' },
        { label: this.$t('Email'), field: 'email' },
        { label: this.$t('Company Role'), field: 'role' },
        { label: this.$t('Project Role'), field: 'projectRole' },
        ...(this.canAccessUserSettings ? [
          { label: this.$t('User Settings'), field: 'settings', sortable: false },
        ] : []),
      ]
    },
  },
  watch: {
    includeInactive() {
      this.getUsers()
    },
  },
  mounted() {
    this.getUsers()
    this.checkUserPermissions()
    EventBus.$on('permission-updated', permission => {
      if (this.permissions.indexOf(permission) !== -1) {
        this.needPermissionCheck = true
        setTimeout(() => {
          this.checkUserPermissions()
          if (permission === 'project.viewusers') this.getUsers()
        }, 2000)
      }
    })
  },
  beforeUnmount() {
    EventBus.$off('permission-updated')
  },
  methods: {
    ...mapActions('permissions', ['setPermissions']),
    checkUserPermissions() {
      if (this.requireUserPermissionCheck || this.needPermissionCheck) {
        checkPermission(this.permissions, permissions => {
          const canInviteUser = permissions['projects.invite'] === true
          const canViewUsers = permissions['project.viewusers'] === true
          const canEditTimingLimit = permissions['timing.limit'] === true
          const canEditUserSettings = permissions['project.usersettings'] === true
          const canManageUsers = permissions['project.manageusers'] === true

          const payload = [
            { key: 'setUserInvitePermission', value: canInviteUser },
            { key: 'setUserViewPermission', value: canViewUsers },
            { key: 'setTimeLimitsPermission', value: canEditTimingLimit },
            { key: 'setUserSettingsEditPermission', value: canEditUserSettings },
            { key: 'setManageUserPermission', value: canManageUsers },
          ]
          this.setPermissions(payload)
          this.needPermissionCheck = false
        })
      }
    },
    getRowStyle(row) {
      return row.projectRoles.data[0]?.deactivatedAt ? 'bg-light-danger' : ''
    },
    getUsers: _.debounce(function () {
      this.isProcessing = true
      const companyId = this.$store.state.project.selectedCompany
      const projectId = this.$store.state.project.selectedProject
      useApollo.auth.listUsers({
        keyword: this.searchTerm, page: this.currentPage, first: this.perPage, companyId, projectId, includeInactive: this.includeInactive,
      }).then(response => {
        const { data } = response
        this.rows = data.users.data.map(user => ({
          ...user,
          avatar: user.avatar,
          role: user.companyRoles?.data[0]?.role?.displayName,
          projectRole: user.projectRoles?.data[0]?.role?.displayName,
        }))
        this.totalItems = data.users.total
      }).finally(() => {
        setComponentReady()
        this.isProcessing = false
      })
    }, 1000),
    viewUserSettings(user) {
      this.userForUserSettings = user
      this.$nextTick(() => { this.showUserSettings = true })
    },
    onCellClick(props) {
      if (props.column.field === 'name') this.selectedUser = props.row
    },
  },
}
</script>

<style lang="scss">
.name-col {
  min-width: 280px;
}
</style>
