<template>
  <div>
    <b-overlay :show="isProcessing">
      <h4 class="mb-2">
        {{ $t('General Settings') }}
      </h4>
      <settings-view
        :settings="settings"
        :form="form"
        :is-loading="isLoading"
      />
      <hr>
      <b-button
        variant="primary"
        class="float-right"
        :disabled="isLoading || isProcessing"
        @click="updateUserGeneralSettings"
      >
        <b-spinner
          v-if="isProcessing"
          small
        />
        {{ $t('Save') }}
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import { BButton, BOverlay, BSpinner } from 'bootstrap-vue'
import SettingsView from '@/views/common/components/SettingsView.vue'

export default {
  components: {
    BButton,
    BOverlay,
    BSpinner,
    SettingsView,
  },
  props: {
    selectedUser: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        disableScreencast: false,
        idleTime: 15,
      },
      settings: {
        disableScreencast: {
          icon: 'MonitorIcon',
          name: 'disableScreencast',
          title: this.$t('settings.screencast'),
          description: this.$t('settings.screencast-info'),
          inputType: 'switch',
          reverse: true,
        },
        idleTime: {
          icon: 'ClockIcon',
          name: 'idleTime',
          title: this.$t('settings.idle-time'),
          description: this.$t('settings.idle-time-info'),
          inputType: 'number',
          append: this.$t('settings.minutes'),
        },
      },
      isLoading: false,
      isProcessing: false,
    }
  },
  mounted() {
    this.getUserGeneralSettings()
  },
  methods: {
    getUserGeneralSettings() {
      this.isLoading = true
      useApollo.users.getUserGeneralSettings({
        userUid: this.selectedUser,
        projectUid: this.$store.state.project.selectedProject,
      }).then(response => {
        const data = response.data.project.users.data[0]?.generalSettings?.data[0]?.information
        this.form = {
          disableScreencast: !!(data && data.disableScreencast),
          enableCloudServices: false,
          idleTime: data && data.idleTime ? data.idleTime : 15,
        }
      }).finally(() => { this.isLoading = false })
    },
    updateUserGeneralSettings() {
      this.isProcessing = true
      useApollo.users.updateUserGeneralSettings({
        userUid: this.selectedUser,
        projectUid: this.$store.state.project.selectedProject,
        input: {
          ...this.form,
          idleTime: Number(this.form.idleTime),
        },
      }).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.updateUserProjectGeneralSettings.message,
          },
        })
      }).finally(() => { this.isProcessing = false })
    },
  },
}
</script>
