<template>
  <b-card
    class="profile-header mb-2"
    :img-src="coverImg"
    img-top
    alt="cover photo"
    body-class="p-0"
  >
    <!-- profile picture -->
    <div class="position-relative">
      <div
        v-if="isProfilePage"
        class="profile-img-container d-flex align-items-center"
      >
        <div class="profile-img text-center pt-2">
          <feather-icon
            icon="UploadIcon"
            size="72"
            color="#333"
          />
          <span class="text-black">
            {{ $t('Upload Image') }}
          </span>
        </div>
      </div>
      <div class="profile-img-container image d-flex align-items-center">
        <b-overlay :show="isProcessing">
          <div :class="{'profile-img-wrapper': isProfilePage}">
            <div
              v-b-modal.profile-img-uploader-modal
              class="profile-img image overflow-hidden"
            >
              <avatar
                :image="avatar ? getAvatar(avatar, 1) : getAvatar(headerData.avatar)"
                :rounded="true"
              />
            </div>
          </div>
        </b-overlay>

        <!-- profile title -->
        <div class="profile-title ml-1 ml-md-3">
          <div class="d-flex">
            <h2
              v-if="!editName"
              class="text-white user-name mr-2"
            >
              {{ name }}
            </h2>
            <b-row
              v-if="self"
              class="align-items-baseline"
            >
              <b-col
                v-if="editName"
                :md="8"
              >
                <input
                  v-model="name"
                  class="form-control bg-transparent border-primary border-2 text-white font-weight-bold mr-0"
                  style="font-size:20px; border-width:2px;"
                  @keypress.enter="name && name !== userData.name ? updateUserName() : editName = false"
                  @keydown.esc="editName = false"
                >
              </b-col>
              <b-col
                :md="4"
                class="px-0 px-md-1"
              >
                <b-spinner
                  v-if="updatingName"
                  variant="light"
                />
                <i
                  v-else-if="editName"
                  class="d-none d-md-inline fa fa-2x fa-check cursor-pointer"
                  :class="!name || name === userData.name ? 'pointer-event-none text-secondary' : 'text-success'"
                  @click="updateUserName"
                />
                <i
                  v-if="!editName"
                  class="fa fa-pencil edit-icon cursor-pointer text-white"
                  @click="editName=true"
                />
              </b-col>
            </b-row>
          </div>
          <p class="text-white">
            {{ headerData.designation || $t('User') }}
          </p>
        </div>
      <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->

    <!-- profile navbar -->
    <div class="profile-header-nav">
      <b-navbar
        toggleable="md"
        type="light"
        style="height: 40px"
      >
        <!-- <b-navbar-toggle
          class="ml-auto"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>

        <b-collapse
          id="nav-text-collapse"
          is-nav
        >
          <b-tabs
            pills
            class="profile-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <template #tabs-start>
              <b-nav-item
                role="presentation"
                active
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">About</span>
                <feather-icon
                  icon="RssIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
            </template>
          </b-tabs>

        </b-collapse> -->
      </b-navbar>
    </div>
    <!--/ profile navbar -->
    <image-uploader
      v-if="isProfilePage"
      @isProcessing="() => { isProcessing = true }"
      @success="()=>{
        isProcessing = false
      }"
    />
  </b-card>
</template>

<script>
import {
  BCard, BNavbar, BOverlay, BSpinner, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Avatar from '@/views/common/components/Avatar.vue'
import EventBus from '@/event-bus'
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData, setUserData } from '@/auth/utils'
import ImageUploader from './ImageUploader.vue'

export default {
  components: {
    BCard,
    BNavbar,
    BOverlay,
    BSpinner,
    ImageUploader,
    Avatar,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      coverImg: require('@/assets/images/pages/study.jpg'),
      avatar: null,
      isProcessing: false,
      editName: false,
      userData: getUserData(),
      name: '',
      updatingName: false,
    }
  },
  computed: {
    isProfilePage() {
      return this.$route.name === 'pages-profile'
    },
    self() {
      return this.userData.uuid === this.headerData.uuid
    },
  },
  mounted() {
    EventBus.$on('updated-avatar', avatar => {
      this.avatar = avatar
      this.isProcessing = false
    })

    this.name = this.headerData.name
  },
  methods: {
    updateUserName() {
      if (this.name !== this.userData.name) {
        this.updatingName = true
        useApollo.users.updateUserProfile({ input: { name: this.name } }).then(() => {
          this.showSuccess('User name updated successfully')

          const userData = { ...this.userData, name: this.name }
          this.userData = userData
          setUserData(userData)
        }).finally(() => {
          this.editName = false
          this.updatingName = false
        })
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.text-black {
  color: #101010;
}
</style>

<style lang="scss">
.vicp-wrap {
  a {
    border: 1px solid !important;
    border-radius: 8px !important;
  }
}
.vicp-icon5,
.vicp-icon6 {
  background-color: #28c76f !important;
}
.pointer-event-none {
  cursor: default;
  pointer-events: none;
}

</style>
