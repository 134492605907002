<template>
  <div>
    <div class="row breadcrumbs-top mb-2">
      <div class="col-12">
        <h2 class="content-header-title float-left pr-1 mb-0" /><div class="breadcrumb-wrapper">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a
                href="/"
                class="router-link-active"
                target="_self"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="align-text-top feather feather-home"
              ><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg></a>
            </li><li class="breadcrumb-item active">
              <span aria-current="location"> {{ $t('Settings') }} </span>
            </li><li class="breadcrumb-item active">
              <span aria-current="location"> {{ $t('Users') }} </span>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location"> {{ user.name }} </span>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <b-button
        variant="flat-secondary"
        @click="$emit('hide')"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          size="15"
        />
        <span class="ml-1">{{ $t('Back') }}</span>
      </b-button>

      <manage-permissions
        v-if="canManagePermissions"
        :permissions="permissionSlugs"
      />
    </div>
    <b-overlay :show="isProcessing">
      <b-tabs
        nav-class="nav-left"
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        nav-wrapper-class="col-md-3 col-12"
        pills
        vertical
      >
        <!-- General Settings -->
        <b-tab v-if="permissions.canEditUserSettings">
          <template #title>
            <span
              class="font-weight-bold"
            >
              <feather-icon
                icon="SettingsIcon"
              />
              {{ $t('General Settings') }}
            </span>
          </template>

          <b-card>
            <general-settings
              v-if="user.uuid"
              :selected-user="user.uuid"
            />
          </b-card>
        </b-tab>

        <!-- Time Limit Settings -->
        <b-tab v-if="permissions.canEditTimingLimit">
          <template #title>
            <span class="font-weight-bold">
              <feather-icon icon="ClockIcon" />
              {{ $t('Time Limit Settings') }}
            </span>
          </template>

          <b-card>
            <time-limit-setting
              v-if="user.uuid"
              :selected-user="user.uuid"
              :can-edit-limit="permissions.canEditTimingLimit"
            />
          </b-card>
        </b-tab>

        <b-tab v-if="permissions.canManageUsers && user.uuid !== self.uuid">
          <template #title>
            <span class="font-weight-bold">
              <feather-icon icon="UserCheckIcon" />
              {{ $t('Change User Status') }}
            </span>
          </template>

          <b-card>
            <user-status-setting
              v-if="user"
              :selected-user="user"
              @refetch-users="() => { $emit('refetch-users'); }"
            />
          </b-card>
        </b-tab>

        <b-tab v-if="isProcessing">
          <b-card>
            {{ $t("Loading") }} ...
          </b-card>
        </b-tab>
      </b-tabs>

      <b-card
        v-if="!permissions.canEditTimingLimit && !permissions.canEditUserSettings && !canViewUsers"
        class="text-center"
      >
        <feather-icon
          icon="FrownIcon"
          size="96"
          color="#ff9f43"
          class="mb-1"
        />
        <h2>{{ $t("Oops!!") }}</h2>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab, BButton, BOverlay,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import ManagePermissions from '@/views/common/components/ManagePermissions.vue'
import TimeLimitSetting from './TimeLimitSetting.vue'
import GeneralSettings from './GeneralSetting.vue'
import UserStatusSetting from './UserStatusSetting.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BButton,
    BOverlay,
    GeneralSettings,
    TimeLimitSetting,
    UserStatusSetting,
    ManagePermissions,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    canViewUsers: {
      type: Boolean,
      default: () => false,
    },
    permissions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      self: getUserData(),
      isProcessing: false,
      permissionSlugs: ['timing.limit', 'project.usersettings', 'project.manageusers'],
      canManagePermissions: this.$store.state.permissions.canManagePermissions.value,
    }
  },
}
</script>
